import React from 'react'
import Link from 'gatsby-link'

import './header.css'

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        {/* LOGO */}
        { !this.props.isHomePage ? 
          <Link to="/" className="logo">J<span>effrey </span>W<span>olff</span></Link> 
        : <div></div> }

        {/* NAVIGATION */}
        <ul className="navigation">
          <li><Link to="/portfolio/">Portfolio</Link></li>
          <li><Link to="/about/">About</Link></li>
          <li><Link to="/contact/" external="true">Contact</Link></li>
        </ul>
        
{/*        { this.props.isPublic || this.props.isHomePage ? 
        : ''}*/}
        

      </div>
    )
  }
}

export default Header
