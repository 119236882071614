import React, { Component } from 'react';

class Clock extends Component {
    constructor(props){
        super(props);
        this.state = { minute:0, second:0}
    }
    componentDidMount() {
        setInterval(()=>{
            return this.setState((state)=>{
                return {
                    second: state.second==999?0:state.second+1
                }
            });
        }, 1000);
    }

    render() {
        return (
            <div className="clock">
                <p>{this.state.second}</p>
            </div>
            
        );
    }
}

export default Clock;